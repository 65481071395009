<template>
    <CModal
      :title="title"
      :color="color"
      size="lg"
      :show.sync="showModal"
    >   
        <CForm>
            
            <CRow>
              <CCol md="8">
                <CInput
                  label="Nombre:"
                  :lazy="false"
                  :value.sync="$v.form.name.$model"
                  :isValid="checkIfValid('name')"
                  placeholder=""
                  autocomplete="given-name"
                  invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
                />
              </CCol>
              <CCol md="4">
                <CSelect
                  label="Proveedor: "
                  :value.sync="$v.form.provider_id.$model"
                  :options="providersOptions"
                  :isValid="checkIfValid('provider_id')"
                  invalidFeedback="Este campo es requerido."
                />
              </CCol>
            </CRow>
        </CForm>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton @click="store" :color="color">Aceptar</CButton>
        </template>
    </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength, email, sameAs, helpers, minValue } from "vuelidate/lib/validators"
import brands from '../../services/brands';
import providers_ws from '../../services/providers';

export default {
  name: 'BrandModal',
  props: {

  },
  data () {
    return {
      showModal: false,
      title: "Nuevo Marca",
      color: "info",
      isEdit: false,
      form: this.getEmptyForm(),
      submitted: false,
      fields: [
        {key: 'name', label: 'Nombre'},
        {key: 'actions', label: '', class: 'actions' }
      ],
      providers: [],
      providersOptions: [],
    }
  },
  mounted: async function() {
    let response = await providers_ws.get(); 

    if(response.type == "success"){
      this.providers = response.data;

      this.providersOptions = await this.$parseSelectOptionsOrdered(this.providers, "id", "name", [{value: '', label: ''}]);
    }
  },
  methods: {
    storeModal () {
      this.showModal = true;
      this.color = "info";
      this.title = 'Nueva Marca';
      this.isEdit = false;
      this.submitted = false;

      this.form = {
          id: 0,
          name: '',
          provider_id: 0
      }
    },
    updateModal (item) {
      this.showModal = true;
      this.color = "warning";
      this.title = 'Editar Marca';
      this.isEdit = true;
      this.submitted = false;

      this.form = {
          id: item.id,
          name: item.name,
          provider_id: item.provider_id
      };
    },
    store () {
      this.$v.$touch();

      if (this.isValid) {
        this.submitted = true;

        if(this.form.id > 0){
          this.$emit("update", this.form );
        }
        else{
          this.$emit("store", this.form );
        }

        this.closeModal();
      }
    },
    closeModal () {
      this.showModal = false;

      this.form = this.getEmptyForm()
      this.submitted = false
      this.$v.$reset()
    },
    getEmptyForm () {
      return {
          id: 0,
          name: '',
          provider_id: 0
      }
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid || field.$model === '')
    },
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(3)
      },
      provider_id: {
        required,
        minValue: minValue(1)
      },
    }
  },
}
</script>